<template>
  <div>
    <MainInfoRealEstate
      :keys="keys"
      :title="$t('property_information')"
    />
    <b-card v-if="details && details.images">
      <h3 class="mb-2">
        {{ $t('images') }}
      </h3>
      <div
        class="real-estate-images"
      >
        <div>
          <h3 class="mb-2">
            {{ $t('thumbnail') }}
          </h3>
          <div class="box-img">
            <img
              :src="details.thumbnail"
              alt="Image"
              class="w-100 custom-object-fit"
            >
          </div>
        </div>
        <div
          v-for="image in details.images"
          :key="image"
          class="box-img"
        >
          <img
            :src="image.original_url"
            alt="Image"
            class="w-100 custom-object-fit"
          >
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import MainInfoRealEstate from '@/components/MainInfoRealEstate.vue'

export default {
  components: {
    MainInfoRealEstate,
  },
  data() {
    return {
      details: null,
      keys: null,
    }
  },
  created() {
    if (this.$route.params.resourceId) {
      this.axios.get(`/real-estate/${this.$route.params.resourceId}`)
        .then(res => {
          this.details = res.data.data
          console.log(this.details)
          this.keys = _.pick(this.details, [
            'title',
            'region',
            'city',
            'district',
            'the_sub_type_of_ad',
            'type',
            'advertiser_name',
            'advertiser_character',
            'advertiser_phone',
            'selling_price',
            'published_at',
            'status_value',
          ])
        })
    }
  },
}
</script>
